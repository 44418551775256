<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}
html {
  background: transparent;
}
body {
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  // background-color: rgb(68, 68, 68);
  color: rgb(68, 68, 68);
}
h1, h2, h3 {
  font-weight: 300;
}
a {
  color: rgb(68, 68, 68);
}

div.loading {
  position: absolute;
  border: 4px solid rgba(255,255,255,0.1);
  border-top: 4px solid rgba(255,255,255,0.6);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 3s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

div.longread {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  h1, h2 {
    margin-bottom: 20px;
  }
  p {
    text-align: left;
    margin-bottom: 20px;
  }
}

</style>
